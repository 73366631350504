/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,900&display=swap');



/* h1,
h2,
h3,
h4,
h5,
h6,
body {
  font-family: 'Playfair Display', serif;
} */

p,
a,
span,
label,
input,
tr,
td,
button,
textarea,
select,
b,
small {
  /* font-family: 'Poppins', sans-serif; */
  color: #414141;
}

@font-face {

  src: url("../fonts/9a7c0b474bb379f1a844d96411a9268f.woff");
  src: url("../fonts/9a7c0b474bb379f1a844d96411a9268f.woff2");
  font-style: normal;
  font-family: "Belymon Script DEMO";
}

/* root Variable */
:root {
  /* color */
  --dark_red: #122d59;
  --white: #fff;
  --dark_yellow: #5d34af;
  --dark_black: #000;

  /* font_family */
  --text_primary: 'Poppins', sans-serif;
  --text_secondry: 'Playfair Display', serif;
  --sp_text: "Belymon Script DEMO";
  --text_footer: 'Poppins';
}

/* end of: Font */

/* comman css */
.text-primary {
  color: var(--dark_red) !important;
}

a:hover {
  color: var(--dark_red);
}

.text-secondary {
  color: var(--dark_yellow) !important;
}

body {
  background-color: var(--white);
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: blue;
}

.btn-primary {
  color: var(--white);
  background-color: var(--dark_red) !important;
  border-color: var(--dark_red) !important;
}

.btn-primary:hover {
  background-color: var(--dark_yellow) !important;
  border-color: var(--dark_yellow) !important;
}

.fs-6 {
  font-size: 0.7rem !important;
}

